import React from 'react'
import Figure from './figure'
import BlockContent from './block-content'
import Button from './button'
import { TrackedLink } from '../components/enhanced-ecommerce'
import HyphenatedTitle from './hyphenatedTitle'

const ProductCard = ({
  product,
  className,
  ...props
}) => {
  const dataLayer = {
    'event': 'productClick',
    'ecommerce': {
      'click': {
        'actionField': {'list': 'Search Results'},
        'products': [{
          'name': product.title,
          'id': product.id,
        }]
      }
    }
  }

  return (
    <TrackedLink
      to={`/${product.category.slug.current}/${product.slug.current}`}
      dataLayer={dataLayer}
      className={`
        inline-block
        no-underline
        p-12
        ${className || ''}
        flex
        flex-col
        relative
      `}
      {...props}
    >
      {product.label && (
        <div className='absolute transform -translate-y-1/2 -translate-x-1/2 top-0 left-1/2 bg-signal rounded-full px-3 py-1 label'>
          {product.label}
        </div>
      )}
      <div className='w-24 h-24 lg:w-32 lg:h-32 mx-auto mb-6 rounded-full overflow-hidden'>
        {product.images && product.images[0] && <Figure className='w-24 lg:w-32' aspectRatio={1} node={product.images[0]} />}
      </div>

      <h3 className='h4 mb-2 font-body font-bold text-black'>
        <HyphenatedTitle text={product.title} />
      </h3>

      {product.lead && <BlockContent className='paragraph-4' blocks={product.lead} />}

      <div className='pt-8 mt-auto text-center'>
        <Button color={`none`} border={true} rounded={true} size={`medium`} className='mb-4'>Les mer her</Button>

        {!!product.defaultPrice && (
          <div className='text-black font-bold'>Kun {product.defaultPrice},–</div>
        )}
      </div>
    </TrackedLink>
  )
}

export default ProductCard
