import React from 'react'
import Helmet from 'react-helmet'

import '../css/style.css'

const Layout = ({children}) => (
  <>
    <Helmet />
    {children}
  </>
)

export default Layout
